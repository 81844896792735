
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

body {
  margin: 0;
  font-family:  "Inter", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

.MuiBackdrop-root.MuiModal-backdrop.css-4nmryk-MuiBackdrop-root-MuiModal-backdrop{
  background: rgba(0, 0, 0, 0) !important;
  /* background: rgb(254, 114, 114) ; */
}


.css-17jr764-MuiPaper-root-MuiDialog-paper{
  border-radius: 30px !important; 
background: #FEF7FF !important;
padding:20px
}